module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b80373e2b6447349c41f3b1d2de6625"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://localhost:3000/49823rhdkjfskjdfh","schema":{"typePrefix":"bs","circularQueryLimit":3,"timeout":12000000,"perPage":2,"requestConcurrency":40,"previewRequestConcurrency":2,"queryDepth":3},"production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":true},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":86400000},"excludeFieldNames":["41bhj8Y3EiL._SX384_BO1,204,203,200_","41bhj8Y3EiL._SX384_BO1,204,203,200_.jpg","pexels-joa?o-jesus-2480072-scaled.jpg"],"type":{"MediaItem":{"localFile":{"requestConcurrency":40,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"preview":true,"timeBuildSteps":true,"disableCompatibilityCheck":true,"throwRefetchErrors":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":true,"onlyReportCriticalErrors":true,"panicOnError":false,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false}},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
